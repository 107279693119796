<template>
    <div>
        <div class="add-card">
            <div class="content-title">抽奖开始时间设置</div>
            <div class="divider-line"/>
            <el-form label-width="140px" label-position="right">
                <el-form-item label="开始时间：">
                    <el-date-picker
                            size="mini"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            v-model="lottery.start_time"
                            type="datetime"
                            placeholder="选择开奖时间"
                            :disabled="!editable"/>
                </el-form-item>
            </el-form>
            <div class="divider-line"/>
        </div>
        
        <div class="add-card">
            <div class="content-title">商品设置</div>
            <div class="divider-line"/>
            <div class="lottery-item" :key="item.index" v-for="(item,index) in lottery.award_list">
                <div class="add-title">
                    <span>{{item.award_name}}</span>
                    <el-button v-if="lottery.award_list.length>1"
                               type="text"
                               style="float: right;margin-right: 24px;margin-top: 5px"
                               @click="deleteItem(item)"
                               :disabled="!editable">
                        删除
                    </el-button>
                </div>
                <el-form label-width="140px" label-position="right">
                    <el-form-item label="奖品类型：" style="margin-top: 10px">
                        <template>
                            <el-radio-group v-model="item.gift_type"
                                            @change="typeChange(index)"
                                            :disabled="!editable">
                                <el-radio :label="1">商品</el-radio>
                                <el-radio :label="2">优惠券</el-radio>
                                <el-radio :label="3">会员权益</el-radio>
                            </el-radio-group>
                            <div v-if="item.gift_type===2" class="add-margin">
                                <el-button type="primary"
                                           icon="el-icon-plus"
                                           @click="toSelectCoupon(index)"
                                           :disabled="!editable">关联优惠券
                                </el-button>
                                <div class="tips" v-if="item.external_id==null">注：选择已有优惠券进行关联</div>
                                <div class="select-tips" v-else><i class="el-icon-info"/>
                                    已关联优惠券#{{item.gift_name}}
                                </div>
                            </div>
                            <div v-if="item.gift_type===3" class="add-margin">
                                <el-button type="primary"
                                           icon="el-icon-plus"
                                           @click="toSelectVip(index)"
                                           :disabled="!editable">选择会员
                                </el-button>
                                <div class="tips" v-if="item.external_id==null">注：请选择会员权益等级</div>
                                <div class="select-tips" v-else><i class="el-icon-info"/>
                                    已选择会员#{{item.gift_name}}
                                </div>
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item label="列表图片：" v-if="item.gift_type===1">
                        <template>
                            <div>
                                <div class="upload brandhead" @click="chooseItemPic(index)">
                                    <Upload :id="'lottery-' +item.index" :oss="config" src="lottery"
                                            @success="uploadPic">
                                        <div class="logo" v-if="item.gift_image_url.url">
                                            <img :src="item.gift_image_url.url" alt="logo" width="100%">
                                            <span class="exchange" v-if="editable">更换图片</span>
                                        </div>
                                        <div class="upload-box flex-center" v-else>
                                            <i class="el-icon-plus"></i>
                                            <span class="text">商品图片</span>
                                        </div>
                                    </Upload>
                                </div>
                                <div class="tips">建议图片尺寸1029x540像素的白底图，且建议大小 &lt;=300KB，
                                    <span class="see-demo" @click="toDemo1">查看示例</span>
                                </div>
                            </div>
                            <el-button type="primary"
                                       icon="el-icon-plus"
                                       @click="toSelectGoods(index)"
                                       class="add-margin"
                                       :disabled="!editable">
                                关联商品
                            </el-button>
                            <div class="tips" v-if="item.external_id==null">
                                <i class="el-icon-warning-outline"></i>注：奖品可新建或者关联小程序中已有商品
                            </div>
                            <div class="select-tips" v-else><i class="el-icon-info"/>
                                已选择商品#{{item.gift_name}}
                            </div>
                        </template>
                    </el-form-item>
                    <el-form-item label="奖品名称：">
                        <el-input placeholder="请输入" v-model="item.gift_name" class="input-item" :disabled="!editable"/>
                    </el-form-item>
                    <el-form-item label="奖品份数：">
                        <el-input-number placeholder="请输入" :step="1" v-model="item.num" :disabled="!editable"/>
                    </el-form-item>
                    <el-form-item label="奖品原价：" v-if="item.gift_type===1">
                        <el-input placeholder="请输入" v-model="item.original_price" class="input-item"
                                  :disabled="!editable"/>
                    </el-form-item>
                    <el-form-item label="商品详情：" v-if="item.gift_type===1">
                        <template>
                            <div @click="chooseItemPic(index)" class="upload-detail">
                                <span v-for="(picItem,detailIndex) in item.detail_imgs_array"
                                      @click="chooseDetailPic(detailIndex)"
                                      :key="'lottery-detail-' +item.index+'-'+detailIndex">
                                    <Upload :id="'lottery-detail-' +item.index+'-'+detailIndex"
                                            src="lottery"
                                            :oss="config"
                                            @success="changeDetailPic"
                                            :disabled="!editable">
                                    <div class="item logo">
                                        <img :src="picItem.url" style="width: 100%;height: 100%"/>
                                        <span class="exchange" v-if="editable">更换</span>
                                        <span class="delete" v-if="editable"
                                              @click.stop="deleteItemDetail(detailIndex)">删除</span>
                                        <div class="line" v-if="editable"/>
                                    </div>
                                    </Upload>
                                </span>
                                <Upload :id="'lottery-detail-add' +item.index" src="lottery"
                                        @success="uploadDetailPic" v-if="editable"
                                        :oss="config">
                                    <div class="upload-detail-box item flex-center">
                                        <i class="el-icon-plus"></i>
                                        <span class="text">上传图片</span>
                                    </div>
                                </Upload>
                            </div>
                            <div class="tips">建议详情图片宽度为1125px。，且大小 &lt;=300kb，支持jpg、png格式，可添加多张</div>
                        </template>
                    </el-form-item>
                </el-form>
            </div>

            <el-button icon="el-icon-plus"
                       @click="addItem"
                       style="width: 100%;border-style: dashed;margin: 16px 36px"
                       :disabled="!editable">添加奖项
            </el-button>
        </div>

        <div class="add-card" style="margin-bottom: 80px">
            <div class="content-title">开奖设置</div>
            <div class="divider-line"/>
            <div style="padding-left: 32px;padding-right: 32px">
                <el-form label-position="top" :inline="true" class="tb-form">
                    <el-form-item>
                        <div>赞助商名称</div>
                        <el-select size="mini" v-model="lottery.brand_id" :disabled="!editable">
                            <el-option v-for="item in brandList"
                                       :label="item.brandName"
                                       :key="item.id"
                                       :value="item.id"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <div>开奖时间</div>
                        <el-date-picker
                                size="mini"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                v-model="lottery.open_time"
                                type="datetime"
                                placeholder="选择开奖时间"
                                :disabled="!editable"/>
                    </el-form-item>
                </el-form>
                <el-form label-position="top" class="tb-form">
                    <el-form-item>
                        <div>上下架状态</div>
                        <el-radio-group v-model="lottery.status" :disabled="!editable">
                            <el-radio :label="1">上架</el-radio>
                            <el-radio :label="0">下架</el-radio>
                        </el-radio-group>
                        <div class="tips"><i class="el-icon-warning-outline"></i>注：奖品可新建或者关联小程序中已有商品</div>
                    </el-form-item>
                </el-form>
                <div class="rule">
                    <div class="rule-head">活动入口</div>
                    <div> 抽奖活动入口位置在，小程序首页-首屏活动banner，<span class="see-demo" @click="toDemo2">查看示例</span></div>
                    <div>注：如果未部署banner请先在后台-运营部署中部署活动banner并关联抽奖活动</div>
                    <div class="rule-head">平台规则</div>
                    <div>
                        1、本抽奖活动为topboom用户专享，每个用户每轮抽奖活动可获得多个抽奖码。其中，在抽奖时间内点击抽奖按钮即可获得首个抽奖码，同时用户将活动分享给好友且好友成功参与后可继续获得抽奖码，目前没有上限；<br/>
                        2、登记结束后，平台将在抽奖时间内从所有抽奖码中随机抽取N个抽奖码为中奖号码，并于该活动页面公示中奖信息，中奖用户可获得当期中奖奖品；<br/>
                        3、中奖用户需在开奖后3个自然日内在平台站内填写收货信息，如因中奖用户未及时填写收货信息或填写收货信息错误导致无法发货的，则视为放弃中奖机会；<br/>
                        4、每轮活动同一用户最多可获得1份中奖奖品，若当轮活动抽取到的多个中奖号码经平台核实属于同一用户则仅算作一次中奖，多余奖品自动取消。虽为不同用户ID，但设备、收货信息、支付信息等相同的，均视为同一用户。<br/>
                        5、若平台发现用户存在批量注册、恶意刷奖等行为，平台有权取消其中奖资格且不予发货。
                    </div>
                    <div class="rule-head">抽奖规则</div>
                    <div>每期活动中奖码将采用随机数通过公式计算得出，细则如下：计算公式：A÷B=C······D（C为商,D为余数）<br/>
                        其中 A=开奖前一天纳斯达克指数与道琼斯指数的组合数列（若前一日不开盘则取离开奖日最近的收盘指数）<br/>
                        例如：当日纳斯达克指数为7385.86，道琼斯指数为26186.71，则A=7385862618671<br/>
                        B=抽奖截至时所有抽奖码总数<br/>
                        例如：当日开奖前共产生了58888个抽奖码，则B=58888<br/>
                        A÷B=7385862618671÷58888= 125422201······46183<br/>
                        中奖码即为46183+每个抽奖活动基数（例如600001）=646184<br/>
                        若有多个中奖名额，则中奖码基于A %(B+n)+600001的结果，其中n=抽奖顺序-1；例如，第一个中奖码基于A %B+600001的结果，第二个中奖码基于A<br/>
                        %(B+1)+600001的结果，并以此类推，直到最后一个中奖码。 若中间出现两个中奖码属于同一个中奖者的情况下，会通过(A+1)
                        %(B+n)+600001的结果进行去重。<br/><br/>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <el-button @click="cancel">
                <span v-if="editable">取消</span>
                <span v-else>关闭</span>
            </el-button>
            <el-button type="primary" @click="createLottery" v-if="editable">
                <span v-if="lottery.id!==null">修改</span>
                <span v-else>创建</span>
            </el-button>
        </div>

        <el-dialog title="关联优惠券" :visible.sync="couponDialogVisible">
            <el-form inline class="tb-form">
                <el-form-item label="优惠券名称：">
                    <el-input placeholder="请输入" v-model="couponSearch"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="requestCouponSearch">查询</el-button>
                </el-form-item>
            </el-form>
            <div class="tablebox">
                <el-table
                        :data="couponList"
                        highlight-current-row
                        @current-change="handleCouponSelectionChange"
                        class="tb-table">
                    <el-table-column width="55px">
                        <template slot-scope="scope">
                            <el-checkbox v-model="scope.row.checked"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column label="优惠券名称" prop="couponName"></el-table-column>
                    <el-table-column label="满减条件" prop="rules"></el-table-column>
                    <el-table-column label="有效期" prop="endTime" width="180px"></el-table-column>
                </el-table>
            </div>
            <div class="bottom-height">
                <div class="bottom-action">
                    <el-button type="primary" @click="selectCoupon">确认关联</el-button>
                    <el-button @click="cancelCoupon">取消</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="vipDialogVisible" width="488px">
            <span class="dialog-title" slot="title">选择会员权益</span>
            <el-radio-group v-model="tempCurrentSelectVip">
                <el-radio v-for="item in vipList" :key="item.id" :label="item">{{item.vipName}}</el-radio>
            </el-radio-group>
            <div class="bottom-height" style="margin-top: 26px">
                <div class="bottom-action">
                    <el-button type="primary" @click="selectVip">确认关联</el-button>
                    <el-button @click="cancelVip">取消</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="goodsDialogVisible" width="800px">
            <span class="dialog-title" slot="title">关联商品</span>
            <el-form :inline="true" label-width="90px">
                <el-form-item label="品牌名称：">
                    <el-select v-model="goodsSearch.brand_id" style="width: 224px">
                        <el-option label="全部品牌" :value="null"/>
                        <el-option v-for="item in brandList"
                                   :key="item.id"
                                   :label="item.brandName"
                                   :value="item.id"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品名称：">
                    <el-input v-model="goodsSearch.name" style="width: 224px"/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearchGoods">查询</el-button>
                </el-form-item>
            </el-form>
            <div class="tablebox">
                <el-table :data="goodsList"
                          highlight-current-row
                          @current-change="handleGoodsSelectionChange"
                          class="tb-table">
                    <el-table-column width="55px">
                        <template slot-scope="scope">
                            <el-checkbox v-model="scope.row.checked"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称" prop="product_name" min-width="200px"/>
                    <el-table-column label="关联品牌" prop="brand_name"/>
                    <el-table-column label="原价" width="140px" prop="market_price"/>
                    <el-table-column label="优惠价" width="140px" prop="sale_price"/>
                </el-table>
                <el-pagination
                        background
                        class="tb-pagination"
                        layout="prev, pager, next"
                        :current-page.sync="goodsPage.no + 1"
                        :page-size="goodsPage.size"
                        :total="goodsPage.total"
                        @current-change="attendGoodsPageChange"/>
            </div>
            <div class="bottom-height">
                <div class="bottom-action">
                    <el-button type="primary" @click="selectGoods">确认关联</el-button>
                    <el-button @click="cancelGoods">取消</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog
                :visible.sync="exampleVisible"
                width="750px">
            <span class="dialog-title" slot="title">{{exampleType===0?'列表图片示例':'活动入口示例'}}</span>
            <img :src="exampleType===0?'https://image.topboom0912.com/lottery/example1.png':'https://image.topboom0912.com/lottery/example2.png'"
                 width="298px" height="554px" style="margin-left: 226px">
            <el-button type="primary" @click="hideExample" style="margin-left: 338px;margin-top: 30px">知道了</el-button>
        </el-dialog>
    </div>
</template>

<script>
    import Upload from '@/components/Upload'

    export default {
        name: "LotteryAdd",
        components: {
            Upload
        },
        data() {
            return {
                lottery: {
                    award_list: [{
                        index: 0,
                        gift_type: 1,
                        external_id: null,
                        gift_name: null,
                        gift_image: null,
                        gift_image_url: {
                            w: 162,
                            h: 156,
                            url: null
                        },
                        detail_imgs: null,
                        detail_imgs_array: [],
                        num: 1,
                        coupon: null,
                        award_name: "奖品",
                        original_price: "",
                        vip: null,
                    }],
                    brand_id: null,
                    open_time: null,
                    start_time: '',
                    status: 1,
                    id: null
                },
                editable: true,
                currentLottery: 0,
                brandList: [],
                couponSearch: "",
                couponList: [],
                couponDialogVisible: false,
                tempCurrentSelectCoupon: null,
                vipList: [],
                vipDialogVisible: false,
                tempCurrentSelectVip: null,
                index: 0,
                detailIndex: 0,
                goodsDialogVisible: false,
                goodsList: [],
                tempCurrentGoods: null,
                goodsSearch: {
                    brand_id: null,
                    name: null
                },
                goodsPage: {
                    no: 0,
                    size: 10,
                    total: null
                },
                exampleType: 0,
                exampleVisible: false,
                config: null
            }
        },
        created() {
            this.getConfig()
        },
        mounted() {
            this.lottery.id = this.$route.query.id * 1 || null
            if (this.lottery.id) {
                this.init()
            }
            this.getBrandList()
            this.requestVipList()
        },
        methods: {
            getConfig() {
                const url = 'common/oss/token?dir=lottery'
                this.$https.get(url).then(data => {
                    if (data) {
                        if (data.host.indexOf('tbresource' === -1)) data.host = 'tbresource.' + data.host
                        if (data.host.indexOf('http' === -1)) data.host = 'https://' + data.host
                        this.config = data
                    }
                })
            },
            async init() {
                const url = "admin/lottery/detail"
                let params = {id: this.lottery.id}
                let data = await this.$https.get(url, {params})
                if (data) {
                    if ((typeof data.start_time).toLowerCase() === 'undefined') {
                        data.start_time = ''
                    }
                    this.lottery = data
                    if (this.lottery.status === 2) {
                        this.editable = false
                    }
                    for (let item of this.lottery.award_list) {
                        if (item.gift_image == null || item.gift_image === "") {
                            this.$set(item, "gift_image_url", {w: 162, h: 156, url: null})
                        } else {
                            this.$set(item, "gift_image_url", JSON.parse(item.gift_image))
                            item.gift_image_url.url = this.$img(item.gift_image)
                        }
                        if (item.detail_imgs == null || item.detail_imgs === "") {
                            this.$set(item, "detail_imgs_array", [])
                        } else {
                            this.$set(item, "detail_imgs_array", this.$img(item.detail_imgs))
                        }
                        item.original_price = item.original_price/100
                    }
                }
            },
            addItem() {
                let awardName = this.sectionToChinese(this.lottery.award_list.length) + "等奖"
                let item = {
                    index: this.lottery.award_list.length,
                    gift_type: 1,
                    external_id: null,
                    gift_name: "",
                    gift_image: null,
                    gift_image_url: {
                        w: 162,
                        h: 156,
                        url: null
                    },
                    detail_imgs: null,
                    detail_imgs_array: [],
                    num: 1,
                    coupon: null,
                    award_name: awardName,
                    original_price: "",
                    vip: null,
                }
                this.lottery.award_list.push(item)
                this.refreshItemAwardName()
            },
            deleteItem(item) {
                let index = this.lottery.award_list.indexOf(item, 0);
                this.lottery.award_list.splice(index, 1)
                this.refreshItemAwardName()
            },
            refreshItemAwardName() {
                if (this.lottery.award_list.length === 1) {
                    this.lottery.award_list[0].award_name = "奖品"
                } else {
                    for (let index = 0; index < this.lottery.award_list.length; index++) {
                        this.lottery.award_list[index].award_name = this.sectionToChinese(index + 1) + "等奖"
                    }
                }
            },
            sectionToChinese(section) {
                const chnNumChar = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
                const chnUnitChar = ["", "十", "百", "千", "万", "亿", "万亿", "亿亿"];
                let strIns = '', chnStr = '';
                let unitPos = 0;
                let zero = true;
                while (section > 0) {
                    const v = section % 10;
                    if (v === 0) {
                        if (!zero) {
                            zero = true;
                            chnStr = chnNumChar[v] + chnStr;
                        }
                    } else {
                        zero = false;
                        strIns = chnNumChar[v];
                        strIns += chnUnitChar[unitPos];
                        chnStr = strIns + chnStr;
                    }
                    unitPos++;
                    section = Math.floor(section / 10);
                }
                return chnStr;
            },
            typeChange(index) {
                this.lottery.award_list[index].gift_name = null
                this.lottery.award_list[index].external_id = null
            },
            toDemo1() {
                this.exampleType = 0
                this.exampleVisible = true
            },
            toDemo2() {
                this.exampleType = 1
                this.exampleVisible = true
            },
            hideExample() {
                this.exampleVisible = false
            },
            async getBrandList() {
                const url = "admin/home/brand/list"
                const data = await this.$https.get(url)
                if (data == null) return
                this.brandList = data
            },
            toSelectCoupon(index) {
                this.currentLottery = index
                this.requestCouponSearch()
                this.couponDialogVisible = true
            },
            async requestCouponSearch() {
                let params = {
                    page_no: 0,
                    page_size: 100,
                    is_valid: 1,
                }
                if (this.couponSearch) params["name"] = this.couponSearch
                const url = "admin/coupon/list"
                let data = await this.$https.get(url, {params})
                this.couponList = data.list
            },
            selectCoupon() {
                if (this.tempCurrentSelectCoupon == null) return
                let item = this.lottery.award_list[this.currentLottery]
                item.coupon = this.tempCurrentSelectCoupon
                item.external_id = this.tempCurrentSelectCoupon.id
                item.gift_name = this.tempCurrentSelectCoupon.couponName
                this.couponDialogVisible = false
                this.tempCurrentSelectCoupon = null
            },
            cancelCoupon() {
                this.couponSearch = ""
                this.couponDialogVisible = false
            },
            handleCouponSelectionChange(row) {
                this.couponList.forEach(item => {
                    // 排他,每次选择时把其他选项都清除
                    if (item.id !== row.id) {
                        item.checked = false
                    } else item.checked = true
                })
                this.tempCurrentSelectCoupon = row
            },
            async requestVipList() {
                const url = "admin/home/vip/list";
                let data = await this.$https.get(url)
                if (data) this.vipList = data
            },
            toSelectVip(index) {
                this.currentLottery = index
                this.vipDialogVisible = true
            },
            selectVip() {
                if (this.tempCurrentSelectVip == null) return
                let item = this.lottery.award_list[this.currentLottery]
                item.vip = this.tempCurrentSelectVip
                item.external_id = this.tempCurrentSelectVip.id
                item.gift_name = this.tempCurrentSelectVip.vipName
                this.vipDialogVisible = false
                this.tempCurrentSelectVip = null
            },
            cancelVip() {
                this.tempCurrentSelectVip = null
                this.vipDialogVisible = false
            },
            cancel() {
                this.$router.back()
            },
            async createLottery() {
                let lottery = JSON.parse(JSON.stringify(this.lottery))
                if (!lottery.start_time) {
                    this.$message.error('请选择抽奖开始时间')
                    return
                }
                if (lottery.brand_id == null) {
                    this.$message.error('请选择赞助商名称')
                    return
                }
                if (lottery.open_time == null) {
                    this.$message.error('请选择开奖时间')
                    return
                }
                if (lottery.start_time >= lottery.open_time) {
                    this.$message.error('抽奖开奖时间不得早于抽奖开始时间')
                    return
                }
                for (let item of lottery.award_list) {
                    item.original_price = item.original_price*100
                    if (item.gift_name == null || item.gift_name === "") {
                        this.$message.error('请输入奖品名称')
                        return
                    }
                    if (item.gift_type === 1) {
                        if (item.gift_image_url.url == null) {
                            this.$message.error('请选择列表图片')
                            return
                        }
                        if (item.original_price == null) {
                            this.$message.error('请输入奖品原价')
                            return
                        }
                    } else if (item.gift_type === 2) {
                        if (item.external_id == null) {
                            this.$message.error('请关联优惠券')
                            return
                        }
                    } else if (item.gift_type === 3) {
                        if (item.external_id == null) {
                            this.$message.error('请关联会员权益')
                            return
                        }
                    }
                }
                let title = this.lottery.id === null ? "确认要提交抽奖活动吗？" : "确认要修改抽奖活动吗？"
                let content = this.lottery.id === null ? "抽奖活动提交后，将在TOPBOOM小程序直接展示，请您确认奖品信息。" : "将会修改已开始的活动信息，请您谨慎操作！"
                this.$confirm(content, title,
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        showClose: false
                    }).then(async () => {
                    const url = "admin/lottery/edit"
                    for (let item of lottery.award_list) {
                        item.gift_image = JSON.stringify(item.gift_image_url)
                        item.detail_imgs = JSON.stringify(item.detail_imgs_array)
                    }
                    let params = lottery
                    let data = await this.$https.post(url, params)
                    if (data) {
                        this.$message.success(this.lottery.id === null ? "创建成功" : "修改成功")
                        this.$router.back()
                    }
                })

            },
            chooseItemPic(index) {
                this.index = index
            },
            chooseDetailPic(index) {
                this.detailIndex = index
            },
            uploadPic(url) {
                this.lottery.award_list[this.index].gift_image_url.url = url
            },
            uploadDetailPic(url) {
                if (this.lottery.award_list[this.index].detail_imgs_array == null) {
                    this.lottery.award_list[this.index].detail_imgs_array = [{w: 162, h: 156, url: null}]
                } else
                    this.lottery.award_list[this.index].detail_imgs_array.push({w: 162, h: 156, url: url})
            },
            changeDetailPic(url) {
                this.$set(this.lottery.award_list[this.index].detail_imgs_array.url, this.detailIndex, {
                    w: 162,
                    h: 156,
                    url: url
                })
            },
            toSelectGoods(index) {
                this.currentLottery = index
                this.goodsDialogVisible = true
                this.goodsPage.no = 0
                this.onSearchGoods()
            },
            async onSearchGoods() {
                const url = "admin/product/list"
                let params = {
                    page_no: this.goodsPage.no,
                    page_size: this.goodsPage.size,
                    sort_by: "3",
                    ...this.goodsSearch
                }
                let data = await this.$https.get(url, {params})
                if (data) {
                    this.goodsList = data.list
                    this.goodsList.forEach(it => {
                        it.market_price = this.$np.divide(it.market_price, 100)
                        it.sale_price = this.$np.divide(it.sale_price, 100)
                    })
                    this.goodsPage.total = data.total_count
                }
            },
            handleGoodsSelectionChange(row) {
                this.goodsList.forEach(item => {
                    // 排他,每次选择时把其他选项都清除
                    if (item.id !== row.id) {
                        item.checked = false
                    } else item.checked = true
                })
                this.tempCurrentGoods = row
            },
            async selectGoods() {
                if (this.tempCurrentGoods == null) return
                let item = this.lottery.award_list[this.currentLottery]
                const url = "admin/product/detail"
                let params = {id: this.tempCurrentGoods.id}
                let goodsData = await this.$https.get(url, {params})
                if (goodsData) {
                    if (goodsData.content_list != null) {
                        for (let contentItem of goodsData.content_list) {
                            if (contentItem.media) {
                                let content = JSON.parse(contentItem.media)
                                content.url = this.$img(contentItem.media)
                                item.detail_imgs_array.push(content)
                            }
                        }
                    }
                }
                item.goods = this.tempCurrentGoods
                item.external_id = this.tempCurrentGoods.id
                item.gift_name = this.tempCurrentGoods.product_name
                item.original_price = this.tempCurrentGoods.market_price
                this.goodsDialogVisible = false
                this.tempCurrentGoods = null
            },
            attendGoodsPageChange(no) {
                this.goodsPage.no = no - 1
                this.onSearchGoods()
            },
            cancelGoods() {
                this.tempCurrentGoods = null
                this.goodsDialogVisible = false
            },
            deleteItemDetail(itemIndex) {
                this.lottery.award_list[this.currentLottery].detail_imgs_array.splice(itemIndex, 1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .upload {
        display: flex;
        flex-direction: column;
        line-height: 1;
        width: 335px;

        &-box {
            width: 100%;
            flex-direction: column;
            border-radius: 4px;
            border: 1px dashed rgba(0, 0, 0, 0.15);
            cursor: pointer;

            .text {
                padding-top: 8px;
                color: rgba(0, 0, 0, 0.65);
            }
        }

        .logo {
            position: relative;
            width: 100%;
            overflow: hidden;
            cursor: pointer;

            .exchange {
                position: absolute;
                display: block;
                width: 100%;
                height: 28px;
                line-height: 28px;
                bottom: 0;
                left: 0;
                font-size: 12px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.45);
                text-align: center;
            }
        }
    }

    .upload-detail {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        line-height: 1;
        width: 664px;

        .item {
            width: 104px;
            margin-right: 4px;
            height: 104px;
        }

        &-box {
            width: 100%;
            flex-direction: column;
            border-radius: 4px;
            border: 1px dashed rgba(0, 0, 0, 0.15);
            cursor: pointer;

            .text {
                padding-top: 8px;
                color: rgba(0, 0, 0, 0.65);
            }
        }

        .logo {
            position: relative;
            width: 104px;
            overflow: hidden;
            cursor: pointer;

            .exchange {
                position: absolute;
                display: block;
                width: 50%;
                height: 28px;
                line-height: 28px;
                bottom: 0;
                left: 0;
                font-size: 12px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.45);
                text-align: center;
            }

            .delete {
                position: absolute;
                display: block;
                width: 50%;
                height: 28px;
                line-height: 28px;
                bottom: 0;
                right: 0;
                font-size: 12px;
                color: #fff;
                background-color: rgba(0, 0, 0, 0.45);
                text-align: center;
            }

            .line {
                position: absolute;
                display: block;
                width: 1px;
                background: white;
                height: 16px;
                bottom: 2px;
                left: 50px;
            }
        }
    }

    .brandhead {
        width: 375px;

        .logo, .upload-box {
            height: 176px;
        }
    }

    .tips {
        color: rgba(0, 0, 0, 0.45);
    }

    .select-tips {
        color: #1890FF;
    }

    .rule {
        color: rgba(0, 0, 0, 0.65);
    }

    .rule-head {
        margin-top: 24px;
        margin-bottom: 8px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 400;
    }

    .input-item {
        width: 435px;
    }

    .bottom {
        background: white;
        position: fixed;
        padding-right: 24px;
        left: 200px;
        right: 0;
        bottom: 0;
        height: 56px;
        line-height: 56px;
        text-align: right;
    }

    .lottery-item {
        border: 1px solid rgb(233, 233, 233);
        margin-top: 8px;
        margin-left: 36px;
        margin-right: 36px;
    }

    .add-title {
        background: #FAFAFA;
        height: 44px;
        line-height: 44px;
        padding-left: 24px;
        border-bottom: 1px solid rgb(233, 233, 233);
    }

    .see-demo {
        color: #1890FF;
        font-weight: 600;
        cursor: pointer;
        text-decoration: underline;
    }

    .bottom-height {
        height: 50px;
    }

    .bottom-action {
        position: absolute;
        left: 40%;
        margin-top: 20px
    }

    .dialog-title {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
    }

    .add-margin {
        margin-top: 20px;
    }

    .add-card {
        background-color: white;
        margin: 24px;
    }

    .content-title {
        padding-left: 32px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        height: 55px;
        line-height: 55px;
    }

    .divider-line {
        width: 100%;
        height: 1px;
        margin-bottom: 30px;
        background: rgba(233, 233, 233, 1);
    }
</style>